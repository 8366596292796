<!--
 * @Version:: 2.2.2
 * @Date: 2022-04-16 11:47:03
 * @LastEditTime: 2023-05-17 20:10:46
 * @LastEditors: cyx 
 * @Description: 
-->
<template>
  <section ref="check" class="check-modal">
    <a-modal
      :visible="visible"
      :title="title"
      centered
      @ok="handleOk"
      :width="655"
      @cancel="handleCancel"
      :getContainer="() => $refs.check"
    >
      <a-row type="flex" class="model-row">
        <a-col :span="12" class="model-col">
          <section class="model-sreach">
            <a-input v-model:value="searchName" :placeholder="placeholder" class="model-input" allow-clear>
              <template #prefix>
                <SearchOutlined />
              </template>
            </a-input>
            <a-button @click="sreachData" class="model-button">查询</a-button>
          </section>
          <section class="model-breadcrumb" v-if="isShowBreadCrumb">
            <a-breadcrumb separator=">">
              <a-breadcrumb-item
                v-for="(item, index) in breadCrumbList"
                :key="item.id"
                @click="breadCrumbClick(item, index + 1)"
                class="model-crumb-item"
                >{{ item.name }}</a-breadcrumb-item
              >
            </a-breadcrumb>
          </section>
          <section class="model-list show-scrollbar">
            <template v-if="renderList.length">
              <div v-for="item in renderList" :key="item.id" class="model-item">
                <a-checkbox
                  v-model:checked="item.checked"
                  @change="handleChange(item)"
                  class="model-checkbox"
                  :indeterminate="item.indeterminate"
                  :disabled="item.disabled"
                >
                  <div class="model-checkbox-item">
                    <img class="model-checkbox-img" :src="item.icon" />
                    <div class="model-checkbox-name single-wrap">{{ item.name }}</div>
                  </div>
                </a-checkbox>
                <template v-if="isShowBreadCrumb">
                  <div
                    class="model-lower-level"
                    :style="{
                      color: item.checked ? '#B8B8B8' : '#C3161C',
                      cursor: item.checked ? 'default' : 'pointer'
                    }"
                    @click.stop="queryLevelStaff(item)"
                    v-if="item.hasOwnProperty('level')"
                  >
                    <span>下级</span><RightOutlined />
                  </div>
                </template>
              </div>
            </template>
            <a-empty v-else :image="simpleImage" :style="{ marginTop: isShowBreadCrumb ? '87px' : '122px' }" />
          </section>
        </a-col>
        <a-col :span="12" class="model-col">
          <h4>已选择人员</h4>
          <section class="model-checked show-scrollbar" v-if="checkedStaff.length">
            <a-row v-for="(item, index) in checkedStaff" :key="item.id" class="model-checked-row">
              <a-col :span="22" style="padding-right: 20px" class="single-wrap">
                <img class="model-checked-img" :src="item.icon" />{{ item.name }}
              </a-col>
              <a-col :span="2" @click="deselect(item, index)" class="model-close"><CloseCircleFilled /></a-col>
            </a-row>
          </section>
          <a-empty v-else :image="simpleImage" style="margin-top: 143px" />
        </a-col>
      </a-row>
    </a-modal>
  </section>
</template>

<script>
import { defineComponent, getCurrentInstance, reactive, ref, toRefs, watch } from 'vue'
import { RightOutlined, CloseCircleFilled, SearchOutlined } from '@ant-design/icons-vue'
import { Empty } from 'ant-design-vue'
import { cmsNotice } from '../../../utils/utils'
import { getLevelList, sreachLevelList } from '../../../apis/sealManage'

export default defineComponent({
  name: 'CheckStaff',
  // 注册你的组件
  components: {
    Empty,
    RightOutlined,
    CloseCircleFilled,
    SearchOutlined
  },
  props: {
    title: {
      type: String,
      default: '请选择'
    },
    visible: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '根据关键字搜索'
    },
    checkedIds: {
      type: Array,
      default: []
    },
    checkedList: {
      type: Array,
      default: []
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      searchName: '',
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      breadCrumbList: [
        {
          id: null,
          name: '常规用印人'
        }
      ],
      isShowBreadCrumb: true,
      renderList: [
        // {
        //   id: 2,
        //   name: '组织架构',
        //   checked: false,
        //   avatar: '@/assets/images/default-avatar.png',
        //   disabled: false,
        //   indeterminate:true
        // },
      ],
      checkedStaff: []
    })
    const handleOk = () => {
      const result = state.renderList.some(item => item.disabled === false)
      if (result) {
        // if (state.checkedStaff.length == 0) {
        //   cmsNotice('error', '请选择常规用印人')
        //   return
        // }
        // let checkedStaff =
        //   props.checkedList.length > 0 ? [...props.checkedList, ...state.checkedStaff] : state.checkedStaff
        emit('checkedResult', state.checkedStaff)
        emit('update:visible', false)
      } else {
        emit('update:visible', false)
      }
    }
    const handleCancel = () => {
      emit('update:visible', false)
    }
    const breadCrumbClick = (val, num) => {
      queryLevelList(val)
      state.breadCrumbList.splice(num)
    }
    const queryLevelStaff = val => {
      if (val.checked) return
      state.breadCrumbList.push(val)
      queryLevelList(val)
    }
    const handleChange = val => {
      console.log(val)
      val.indeterminate = false
      if (val.checked) {
        state.checkedStaff.push(val)
        let tmp = []
        state.breadCrumbList.forEach(item => {
          if (item.id) tmp.push(item.id)
        })
        val.levelIds = Array.from(new Set(tmp))
      } else {
        state.checkedStaff.forEach((item, index) => {
          if (item.id == val.id) {
            state.checkedStaff.splice(index, 1)
          }
        })
        let arrTmp = [...state.checkedStaff]
        if (val.type == 'staff') {
          state.checkedStaff = arrTmp.filter(item => item != val)
        } else {
          state.checkedStaff = arrTmp.filter(item => item.levelIds.indexOf(val.id) == -1)
        }
      }
      console.log(state.checkedStaff, '23213')
    }
    const deselect = (val, index) => {
      state.renderList.forEach(item => {
        if (item.id == val.id) {
          item.checked = false
          item.disabled = false
        }
      })
      state.checkedStaff.splice(index, 1)
      disabledCheckStaff()
    }

    const queryLevelList = async item => {
      const res = await getLevelList({ deptId: item ? item.id : null })
      if (res.success) {
        state.isShowBreadCrumb = true
        handleData(res.data)
      }
    }

    const sreachData = async () => {
      const res = state.searchName
        ? await sreachLevelList({ searchName: state.searchName })
        : queryLevelList(state.breadCrumbList[state.breadCrumbList.length - 1])
      if (res.success) {
        state.isShowBreadCrumb = false
        handleData(res.data)
      }
    }

    // 获取当前左侧是否选中
    const handleData = arr => {
      state.renderList = arr.map(item => {
        const newItem = state.checkedStaff.find(
          el =>
            (typeof el.staffId !== 'undefined' && el.staffId === item.staffId) ||
            (typeof el.departmentId !== 'undefined' && el.departmentId === item.departmentId)
        )
        item.name = item.hasOwnProperty('level') ? item.departmentName : item.staffName
        item.id = item.hasOwnProperty('level') ? item.departmentId : item.staffId
        item.type = item.hasOwnProperty('level') ? 'depart' : 'staff'
        item.checked = newItem ? newItem.checked : false
        item.disabled = false
        item.indeterminate = false
        item.levelIds = []
        item.icon = item.hasOwnProperty('level')
          ? require('@/assets/images/checkStaff/level.png')
          : item.avatarId
          ? item.fullAvatar
          : require('@/assets/images/default-avatar.png')
        return item
      })
      disabledCheckStaff()
    }

    const disabledCheckStaff = () => {
      // let arr = [...arrChecked, ...state.checkedStaff]
      let arrID = state.checkedStaff.map(item => {
        return { id: item.id, type: item.type }
      })
      state.renderList.forEach(item => {
        item.indeterminate = boolResult(state.checkedStaff, item.id, item.type) ? true : false
        if (arrID.find(e => item.id == e.id && item.type == e.type)) {
          // item.disabled = boolRes(arr,item.id) ? true : false
          item.indeterminate = false
          item.checked = true
        }
      })
    }

    const boolResult = (arr, id, type) => {
      let result = false
      arr.forEach(val => {
        if (val['levelIds'].indexOf(id) != -1 && val['type'] != type) {
          result = true
        }
      })
      return result
    }
    const boolRes = (arr, id) => {
      let result = false
      let filterRes = arr.filter(item => item.id == id)
      if (filterRes.length) {
        if (filterRes[0].hasOwnProperty('type')) {
          result = true
        }
      }
      return result
    }

    watch(
      () => props.visible,
      newValue => {
        if (newValue) {
          state.checkedStaff = props.checkedList.map(item => {
            item['type'] = 1
            return item
          })
          queryLevelList(null)
        }
      },
      { immediate: true }
    )

    return {
      ...toRefs(state),
      handleOk,
      handleCancel,
      sreachData,
      queryLevelStaff,
      deselect,
      handleChange,
      disabledCheckStaff,
      queryLevelList,
      breadCrumbClick
    }
  }
})
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
